// Full Calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from "vue";
// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import store from '@/store'

export default function userCalendar() {
  // Use toast
  const toast = useToast()
  // ------------------------------------------------
  // refCalendar
  // ------------------------------------------------
  const refCalendar = ref(null)

  // ------------------------------------------------
  // calendarApi
  // ------------------------------------------------
  let calendarApi = null
  // onMounted(() => {
  //   calendarApi = refCalendar.value.getApi()
  //   console.log("calendarApi",calendarApi)
  // })
  const listevent=ref([])

  // ------------------------------------------------
  // calendars
  // ------------------------------------------------
  const calendarsColor = {
    Business: 'primary',
    Holiday: 'success',
    Personal: 'danger',
    Family: 'warning',
    ETC: 'info',
  }

  // ------------------------------------------------
  // event
  // ------------------------------------------------
  const blankEvent = {
    id: '',
    date: '',
    end: '',
    title: '',
    city_id: [],
    course_name:''
   
  }
  const DeltedEventsList=ref([])
  const event = ref(JSON.parse(JSON.stringify(blankEvent)))
  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent))
  }
  const clearEventDataall = (event) => {
    event.value = JSON.parse(JSON.stringify(blankEvent))
  }

 const removeAllEvents=()=>{
  console.log("dayGridPlugin",dayGridPlugin)
    listevent.value=[]
    listevent.value.forEach(el=>{
      removeEventInCalendar(el.id)
    })

  }

  // *===========================================================================---*
  // *--------- Calendar API Function/Utils --------------------------------------------*
  // Template Future Update: We might move this utils function in its own file
  // *===========================================================================---*

  // ------------------------------------------------
  // (UI) addEventInCalendar
  // ? This is useless because this just add event in calendar and not in our data
  // * If we try to call it on new event then callback & try to toggle from calendar we get two events => One from UI and one from data
  // ------------------------------------------------
  // const addEventInCalendar = eventData => {
  //   toast({
  //     component: ToastificationContent,
  //     position: 'bottom-right',
  //     props: {
  //       title: 'Event Added',
  //       icon: 'CheckIcon',
  //       variant: 'success',
  //     },
  //   })
  //   calendarApi.addEvent(eventData)
  // }

  // ------------------------------------------------
  // (UI) updateEventInCalendar
  // ------------------------------------------------
  const updateEventInCalendar = (updatedEventData, propsToUpdate, extendedPropsToUpdate) => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Updated',
        icon: 'CheckIcon',
        variant: 'success',
      },
    })

    const existingEvent = calendarApi.getEventById(updatedEventData.id)

    // --- Set event properties except date related ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setProp
    // dateRelatedProps => ['start', 'end', 'allDay']
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < propsToUpdate.length; index++) {
      const propName = propsToUpdate[index]
      existingEvent.setProp(propName, updatedEventData[propName])
    }

    // --- Set date related props ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setDates
    existingEvent.setDates(updatedEventData.start, updatedEventData.end, { allDay: updatedEventData.allDay })

    // --- Set event's extendedProps ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setExtendedProp
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < extendedPropsToUpdate.length; index++) {
      console.log("extendedPropsToUpdate[index]",extendedPropsToUpdate[index])
      const propName = extendedPropsToUpdate[index]

      existingEvent.setExtendedProp(propName, updatedEventData.extendedProps[propName])
    }
  }

  // ------------------------------------------------
  // (UI) removeEventInCalendar
  // ------------------------------------------------
  const removeEventInCalendar = eventId => {
    // toast({
    //   component: ToastificationContent,
    //   props: {
    //     title: 'Event Removed',
    //     icon: 'TrashIcon',
    //     variant: 'danger',
    //   },
    // })
    console.log("eventId",eventId)
    console.log(" listevent.value", listevent.value)

    listevent.value = listevent.value.filter(l => {
      l.id !== eventId
  })
  listevent.value.push(blankEvent)
  console.log(" listevent.value", listevent.value)
  }

  // ------------------------------------------------
  // grabEventDataFromEventApi
  // ? It will return just event data from fullCalendar's EventApi which is not required for event mutations and other tasks
  // ! You need to update below function as per your extendedProps
  // ------------------------------------------------
  const grabEventDataFromEventApi = eventApi => {
    
    const {
      id,
      date,
      title,
      start,
      end,
      city_id,
      course_name,
      // eslint-disable-next-line object-curly-newline
     
      allDay,
    } = eventApi

    return {
      id,
      title,
      date,
      start,
      end,
      city_id,
      course_name,
     
      allDay,
    }
  }

  // ------------------------------------------------
  // addEvent
  // ------------------------------------------------
  const addEvent = eventData => {
    console.log("eventData",eventData)
  if(eventData.city.length > 1){
    eventData.city.forEach(el=>{
      calendarOptions.value.events.push({
        title:'ee',
        date:eventData.start,
        start:eventData.start,
        end:'',
        city_id:eventData.city_id,
        course_name:eventData.course_name

      })
    })
  }
  else{
    calendarOptions.value.events.push(eventData)
  }  
  DeltedEventsList.value.push(eventData)
  console.log("calendarOptions",calendarOptions)
  }

  // ------------------------------------------------
  // updateEvent
  // ------------------------------------------------
  const updateEvent = eventData => {
    store.dispatch('calendar/updateEvent', { event: eventData }).then(response => {
      const updatedEvent = response.data.event

      const propsToUpdate = ['id', 'title', 'url',' city_id','course_name']
      const extendedPropsToUpdate = ['name', 'guests', 'location', 'description']

      updateEventInCalendar(updatedEvent, propsToUpdate, extendedPropsToUpdate)
    })
  }

  // ------------------------------------------------
  // removeEvent
  // ------------------------------------------------
  const removeEvent = () => {
    const eventId = event.value.id
    store.dispatch('calendar/removeEvent', { id: eventId }).then(() => {
      removeEventInCalendar(eventId)
    })
  }

  // ------------------------------------------------
  // refetchEvents
  // ------------------------------------------------
  const refetchEvents = () => {
    calendarApi.refetchEvents()
  }

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const selectedCalendars = computed(() => store.state.schedule.selectedCalendars)

  watch(selectedCalendars, () => {
    fetchEvents()
  })

  // --------------------------------------------------------------------------------------------------
  // AXIOS: fetchEvents
  // * This will be called by fullCalendar to fetch events. Also this can be used to refetch events.
  // --------------------------------------------------------------------------------------------------
  const fetchEvents = (info, successCallback) => {
    // If there's no info => Don't make useless API call
  // console.log("params",params)
    // Fetch Events from API endpoint
    if(selectedCalendars.value===null){
    console.log("selectedCalendars.value")
    }
    else{
    store
      .dispatch('schedule/fetchEvents', selectedCalendars.value )
      .then(response => {
        console.log("responsefetch",response)
        // successCallback(response.data.data)
        listevent.value=response.data.data
        console.log("responsefetch",response)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching calendar events',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }
  }
  Array.prototype.includesObj = function(obj) {
    for(let i = 0; i < this.length; i++) {
       if(JSON.stringify(this[i], Object.keys(this[i]).sort()) === JSON.stringify(obj, Object.keys(obj).sort())) return true;
    }
    return false;
 }
  // ------------------------------------------------------------------------
  // calendarOptions
  // * This isn't considered in UI because this is the core of calendar app
  // ------------------------------------------------------------------------
  const today = new Date();
  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: 'dayGridMonth',
    headerToolbar: {
      start: 'sidebarToggle, prev,next, title',
      end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
    },
    initialDate: today,
    events: listevent,

    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    editable: true,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: true,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: true,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 2,

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: true,

    eventClassNames({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      console.log("evefr",event)
      console.log("evefr",calendarEvent)
      
      const colorName = calendarsColor[calendarEvent._def.extendedProps.calendar]

      return [
        // Background Color
        `bg-light-${colorName}`,
      ]
    },
    eventClick({ event: clickedEvent }) {
    
      // * Only grab required field otherwise it goes in infinity loop
      // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
      event.value = grabEventDataFromEventApi(clickedEvent)
      console.log("clickedEvent",clickedEvent._def.publicId)

      // eslint-disable-next-line no-use-before-define
      Vue.swal({
        icon: "warning",
        title: "هل انت متاكد تريد الحذف?" ,
        confirmButtonText: "نعم",
        cancelButtonText: "الغاء",
        customClass: {
          confirmButton: "btn btn-primary",
       
        },
      }).then((result) => {
        if (result.value) {
          console.log("  listevent.value",  listevent.value)

        //  listevent.value =listevent.value.filter(
        //   l => {
        //     parseInt(l.id) !== parseInt(clickedEvent._def.publicId)}
        
        //   );
          listevent.value= listevent.value.filter(x => {
            return x.id != clickedEvent._def.publicId;
          })

 
          DeltedEventsList.value.push(clickedEvent)
          // removeEventInCalendar(clickedEvent.id)
          console.log("  listevent.value",  listevent.value)
        }})
      // isEventHandlerSidebarActive.value = true
    },

    customButtons: {
      sidebarToggle: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: 'sidebar',
        click() {
          // eslint-disable-next-line no-use-before-define
          isCalendarOverlaySidebarActive.value = !isCalendarOverlaySidebarActive.value
        },
      },
    },

    dateClick(info) {
      /*
        ! Vue3 Change
        Using Vue.set isn't working for now so we will try to check reactivity in Vue 3 as it can handle this automatically
        ```
        event.value.start = info.date
        ```
      */


        console.log("listevent.value",listevent.value)
        console.log("selectedCalendars.value",selectedCalendars.value)
        // console.log("listevent",listevent.value.includes(selectedCalendars.value))

      event.value = JSON.parse(JSON.stringify(Object.assign(event.value, { start: info.date })))
      // eslint-disable-next-line no-use-before-define
 
     
        selectedCalendars.value.city_id.forEach(el=>{
          let obj={
            title:el.name,
            date:info.date ,
            start:info.date ,
            end:'',
            city_id:el.id,
            
    
          }
          obj.course_name=selectedCalendars.value.course_id.name
          obj.course_id=selectedCalendars.value.course_id.id
          console.log("obj",obj)
          console.log("  calendarOptions.value", listevent.value.includesObj(obj))
          if(!listevent.value.includesObj(obj)){
            calendarOptions.value.events.push(obj)
          }
         
        })
        
        selectedCalendars.value.city_id.forEach(el=>{
          let obj={
            title:el.name,
            date:info.date ,
            start:info.date ,
            end:'',
            city_id:el.id,
            category_id:selectedCalendars.value.category_id,
           }
           obj.course_name=selectedCalendars.value.course_id.name
           obj.course_id=selectedCalendars.value.course_id.id
            console.log("DeltedEventsList.value", DeltedEventsList.value.includesObj(obj))
            if(!DeltedEventsList.value.includesObj(obj)){
              DeltedEventsList.value.push(obj)
            }
         
         
        })
       
    
      console.log("DeltedEventsList",DeltedEventsList)


      // isEventHandlerSidebarActive.value = true
    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    eventDrop({ event: droppedEvent }) {
      console.log("droppedEvent",droppedEvent)
      console.log("event",event)
      updateEvent(grabEventDataFromEventApi(droppedEvent))
    },

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize({ event: resizedEvent }) {
      updateEvent(grabEventDataFromEventApi(resizedEvent))
    },

    // Get direction from app state (store)
    direction: computed(() => (store.state.appConfig.isRTL ? 'rtl' : 'ltr')),
    rerenderDelay: 350,
  })

  // ------------------------------------------------------------------------

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const isEventHandlerSidebarActive = ref(false)

  const isCalendarOverlaySidebarActive = ref(false)

  return {
    refCalendar,
    isCalendarOverlaySidebarActive,
    calendarOptions,
    listevent,
    event,
    clearEventData,
    removeAllEvents,
    clearEventDataall,
    addEvent,
    updateEvent,
    removeEvent,
    refetchEvents,
    fetchEvents,
    removeEventInCalendar,
    DeltedEventsList,

    // ----- UI ----- //
    isEventHandlerSidebarActive,
  }
}
