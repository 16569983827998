<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div class="col app-calendar-sidebar flex-grow-0 d-flex flex-column">
        <b-form
          ref="form"
          class="mt-2"
          style="width: 100%"
          @submit.prevent="getDate"
        >
          <b-col md="12">
            <b-form-group
              label="الفئة"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <v-select
                id="blog-edit-category"
                v-model="addScheduleForm.category_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="type"
                :options="categoriesList"
                :reduce="(val) => val.id"
                @input="getCoursesByCategory(addScheduleForm.category_id)"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="الدورة "
              label-for="blog-edit-course"
              class="mb-2"
            >
              <v-select
                id="blog-edit-category"
                v-model="addScheduleForm.course"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="coursesList"
              >
                <template
                  v-if="!getCourse"
                  #no-options="{ search, searching, loading }"
                >
                  اختر الفئة اولا ...
                </template>
                <template
                  v-else
                  #no-options="{ search, searching, loading }"
                >
                  جار التحميل ...
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="المدينة"
              label-for="blog-edit-course"
            >
              <div
                v-for="(city, i) in citiesList"
                :key="city.id"
              >
                <b-form-checkbox
                  :id="city.id.toString()"
                  v-model="addScheduleForm.city"
                  :value="city"
                  class="mb-2"
                  @change="getDate"
                >
                  {{ city.name }}
                </b-form-checkbox>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mt-10"
          >
            <b-button
              variant="primary"
              class="mr-1"
              @click="getChanges"
            >
              عرض التغيرات
            </b-button>
          </b-col>
        </b-form>
      </div>

      <!-- Calendar -->

      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
              v-if="renderComponent"
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar"
            />
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
      <div
        class="body-content-overlay"
        :class="{ show: isCalendarOverlaySidebarActive }"
        @click="isCalendarOverlaySidebarActive = false"
      />
    </div>

    <b-row>
      <list-changes
        ref="table"
        @remove-change="emptyChange"
      />
    </b-row>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import {
  ref, reactive, computed, onUnmounted, nextTick,
} from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BButton,
  BForm,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Vue from 'vue'
import store from '@/store'

import calendarStoreModule from './calendarStoreModule'
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import useCalendar from './useCalendar'
import ListChanges from './ListChanges.vue'

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    ListChanges,
    CalendarEventHandler,
    BRow,
    vSelect,
    BButton,
    BForm,
    BFormGroup,
    BCard,
    BFormCheckbox,
    BCol,
  },
  setup() {
    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      DeltedEventsList,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,
      removeAllEvents,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar()

    const addScheduleForm = reactive({
      category_id: 0,
      city: [],
      course: 0,
    })
    const table = ref()
    const coursesList = ref([])
    const getDate = () => {
      store.commit('schedule/SET_SELECTED_EVENTS', {
        course_id: addScheduleForm.course,
        city_id: addScheduleForm.city,

        category_id: addScheduleForm.category_id,
        online: 0,
      })
    }

    const renderComponent = ref(true)
    const forceRerender = async () => {
      // Remove MyComponent from the DOM
      renderComponent.value = false

      // Wait for the change to get flushed to the DOM
      await nextTick()

      // Add the component back in
      renderComponent.value = true
    }
    const showChange = ref(false)
    const getCourse = ref(false)
    const categoriesList = ref([])
    const citiesList = ref([])

    store.dispatch('cities/cititesList').then(response => {
      console.log('response', response.data)
      citiesList.value = response.data
    })
    const emptyChange = () => {
      // window.location.reload()
      // refCalendar.value.render()
      forceRerender()
      console.log('refCalendar.value', refCalendar.value)
      const today = new Date()
      console.log('DeltedEventsList.DeltedEventsList', DeltedEventsList.value)
      DeltedEventsList.value = []
      addScheduleForm.course = ''
      addScheduleForm.category_id = ''
      addScheduleForm.city = []
      store.state.schedule.selectedCalendars=[]
      // console.log(refCalendar.value.removeAllEvents())
      // refCalendar.value.removeAllEvents();
      removeAllEvents()
      calendarOptions.value.initialDate = today
      console.log('calendarOptions', calendarOptions)
      console.log('DeltedEventsList.DeltedEventsList', DeltedEventsList.value)
    }

    const getCoursesByCategory = id => {
      addScheduleForm.course_id = ''
      getCourse.value = true
      store
        .dispatch('categories/getCoursesByCategory', {
          online: 0,
          ' category_id': id,
        })
        .then(response => {
          console.log('response', response.data)
          coursesList.value = response.data.data
        })
    }
    store.dispatch('categories/CategoryList').then(response => {
      categoriesList.value = response.data
    })
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) { store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) { store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME) }
    })
    const getChanges = () => {
      // showChange.value=true
      if (DeltedEventsList.value.length == 0) {
        console.log('hi', DeltedEventsList)
        Vue.swal({
          text: 'يجب على الاقل ان تقوم بأضافة او حذف دورة مجدولة',
          icon: 'error',
          confirmButtonText: 'حسنا',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        table.value.fetchData(DeltedEventsList)
      }
    }

   
    return {
      showChange,
      table,
      renderComponent,
      forceRerender,

      emptyChange,
      removeAllEvents,
      getChanges,
      refCalendar,
      DeltedEventsList,
      isCalendarOverlaySidebarActive,
      event,
      getCoursesByCategory,
      clearEventData,
      addEvent,
      addScheduleForm,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,
      coursesList,
      categoriesList,
      citiesList,
      getDate,
      getCourse,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    }
  },
}
</script>

<style lang="scss">
.fc-event-time {
  display: none;
}
@import "@core/scss/vue/apps/calendar.scss";
</style>
